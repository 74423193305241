import { removeAllFromClass } from './dom-helpers';
const popupClass = 'js-custom-popup';
const closePopupClass = 'js-custom-popup-close';
const activeClass = 'is-active';

export function openPopup(elem) {
    document.documentElement.style.overflowY = 'hidden';
    removeAllFromClass(popupClass, activeClass);

    elem.classList.add(activeClass);
}

export function closePopup() {
    removeAllFromClass(popupClass, activeClass);
}

document.addEventListener('click', function (e) {
    if (e.target.matches('.' + closePopupClass)) {
        closePopup();
        document.documentElement.style.overflowY = 'auto';
    }
});
