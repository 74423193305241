import { openPopup } from '../helpers/popup-helper';
const popupTriggerClass = 'js-contact-form-popup';

function setPopupTriggerButtons() {
    const popupTriggerButtons = document.querySelectorAll(
        `.${popupTriggerClass}`
    );
    if (popupTriggerButtons) {
        popupTriggerButtons.forEach(item => {
            item.addEventListener('click', function (e) {
                e.preventDefault();

                const popupId = e.currentTarget.dataset.target;
                const popup = document.getElementById(popupId);

                if (popup) {
                    openPopup(popup);
                }
            });
        });
    }
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    setPopupTriggerButtons();
} else {
    document.addEventListener('DOMContentLoaded', setPopupTriggerButtons);
}
